import React from 'react'
import Typography from '@material-ui/core/Typography'

const BaseFooter = React.lazy(() => import('./BaseFooter'))

function TextContent() {
    return (
        <>
            <br />
            <br />
            <Typography variant="h6" color="initial">
                Frequenty Asked Questions
            </Typography>
            <Typography variant="subtitle1" color="initial">
                How to whatsapp anyone without saving phone number in contact list?
            </Typography>
            <ol>
                <li>Open Wand.ga</li>
                <li>enter the number where you want to send message</li>
                <li>tap on Message button</li>
                <li>it will redirect you to whatsapp, now enjoy chat with unsavd number</li>
            </ol>

            <Typography variant="subtitle1" color="initial">
                What is Wand?
            </Typography>
            <Typography variant="subtitle2" color="initial">
                Wand is a tool to whatsapp anyone without saving phone number
            </Typography>

            <Typography variant="subtitle1" color="initial">
                Is Wand safe?
            </Typography>
            <Typography variant="subtitle2" color="initial">
                Yes, we never store any message or document or any other kind of media you sent to the entered number
            </Typography>
            <React.Suspense fallbback={<>Loading...</>}>
                <BaseFooter />
            </React.Suspense>
        </>
    )
}

export default TextContent
